<template>
    <section class="section section--gamehot" :class="{'section--background': !$isEnableThemeNoel()}">
        <img v-if="$isEnableThemeNoel()" class="event-leaf top-mb left bottom width-2" src="/assets/img/event/noel/home/leaf-left-2.svg" alt="leaf">
        <b-container>
            <div class="section__title text-center">
                <div class="start-title">
                    <img src="/assets/img/line-star.png" alt="start" loading="lazy"/>
                </div>
                <div class="heading h2"><span>GAMES HOT</span></div>
            </div>
            <b-row class="list-game">
                <b-col cols="6" lg="4" md="4" class="list-game-mobile" v-for="(item, index) in menuItems" :key="index">
                    <div class="list-game-item">
                        <a href="javascript:void(0)" @click.prevent="moveTo(item)">
                            <s-img v-if="item.label === $LABEL_TYPE.LABEL_EVENT" class="label"
                                   src="/assets/img/event/icon-event.svg" alt="event"/>
                            <img v-if="$isEnableThemeNoel()" src="/assets/img/event/noel/home/bg-game-event-noel.png" loading="lazy" :alt="item.title"/>
                            <img v-else-if="$isEnableThemeHoliday()" src="/assets/img/event/holiday/home/bg-game-event-holiday.png" loading="lazy" :alt="item.title"/>
                            <img v-else src="/assets/img/gamehot/bg-game.png" loading="lazy" :alt="item.title"/>
                            <div class="effect-icon">
                                <img v-if="item.iconEvent && $isEnableThemeWorldcup()" :src="item.iconEvent" :alt="item.title"/>
                                <img v-else :src="item.icon" :alt="item.title"/>
                            </div>
                            <div class="effect">
                                <img v-if="item.iconEffectEvent && $isEnableThemeWorldcup()" :src="item.iconEffectEvent" loading="lazy" :alt="item.title"/>
                                <img v-else :src="item.iconEffect" loading="lazy" :alt="item.title"/>
                            </div>
                            <div class="title flex-box just-center">
                                {{ item.title }}
                            </div>
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <img v-if="$isEnableThemeNoel()" class="event-leaf top-mb width-1" src="/assets/img/event/noel/home/leaf-right-2.svg" alt="leaf">
    </section>
</template>
<script>
export default {
    data() {
        return {
            menuItems: [
                {
                    title: 'BẮN CÁ',
                    link: '/tro-choi?tab=macau&type=ban-ca',
                    icon: '/assets/img/gamehot/fish.png',
                    iconEffect: '/assets/img/gamehot/effect-fish.png',
                    newtab: true,
                    playGame: {
                        display_types: [this.$GAME_DISPLAY_TYPE.SLOT],
                        type: this.$GAME_TYPE.ASIA,
                        partner_provider: this.$PARTNER.TECHPLAY,
                        partner_game_id: 'ktf1999'
                    }
                },
                {
                    title: 'thể thao',
                    link: '/the-thao',
                    icon: '/assets/img/gamehot/sport.png',
                    iconEffect: '/assets/img/gamehot/effect-sport.png',
                    iconEvent: '/assets/img/gamehot/sport-event.png',
                    iconEffectEvent: '/assets/img/gamehot/effect-sport-event.png',
                    newtab: false,
                    playGame: null,
                    label: this.$LABEL_TYPE.EVENT_WHEEL
                },
                {
                    title: 'lô đề',
                    link: '/tro-choi-lo-de',
                    icon: '/assets/img/gamehot/lode.png',
                    iconEffect: '/assets/img/gamehot/effect-lode.png',
                    newtab: false,
                    playGame: null
                },
                {
                    title: 'GAME BÀI',
                    link: '/tro-choi?tab=macau&type=game-bai',
                    icon: '/assets/img/gamehot/game-bai.png',
                    iconEffect: '/assets/img/gamehot/effect-game-bai.png',
                    newtab: false,
                    playGame: null
                },
                {
                    title: 'Quay số',
                    link: '/tro-choi?tab=macau&type=quay-so',
                    icon: '/assets/img/gamehot/lottery.png',
                    iconEffect: '/assets/img/gamehot/effect-lottery.png',
                    newtab: false,
                    playGame: null
                },
                {
                    title: 'live casino',
                    link: '/livecasino',
                    icon: '/assets/img/gamehot/livecasino.png',
                    iconEffect: '/assets/img/gamehot/effect-casino.png',
                    newtab: false,
                    playGame: null
                }
            ]
        };
    },
    methods: {
        moveTo(item) {
            if (item.playGame !== null) {
                this.$playGame(item.playGame);
            } else if (item.newtab && this.$isMobile()) {
                window.open(item.link, '_blank');
            } else {
                this.$router.push(item.link);
            }
        }
    }
};
</script>
